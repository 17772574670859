.ir_wrapper {
    display: flex;
    justify-content: space-between;

    &_heading {
        display: none;
        font-family:  'Roboto Mono', monospace;
    }

    &_contents {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        &_text {
            width: 67vw;
            text-align: justify;
            display: flex;
            flex-direction: column;
            align-items: center;
            &_heading {
                width: 33vw;
                font-family:  'Roboto Mono', monospace;
                color: #3E3636;
                font-size: 40px;
                margin-right: 10vw;
            }

            &_intropara {
                width: 33vw;
                font-family: 'Work Sans', sans-serif;
                color: #747474;
                line-height: 200%;
                margin-right: 10vw;
            }

            &_stages {
                z-index: 99;
                width: 33vw;
                margin-right: 10vw;
                &_one, &_two, &_three, &_four {
                    &_title {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;
                        font-family: 'Work Sans', sans-serif;
                        color: #3E3636;
                        border-bottom: 1px solid rgba(62, 54, 54, 0.12);
                        &_openicon {
                            background-image: url('./../../assets/closeicon.png');
                            width: 0.9vw;
                            height: 0.9vw;
                            background-size: contain;
                            background-repeat: no-repeat;
                        }
                        &_text {
                            margin-left: 1vw;
                            font-weight: 700;
                            font-size: 1vw;
                            line-height: 23px;
                            letter-spacing: 0.06em;

                            &_paragraph{
                                font-family: 'Work Sans', sans-serif;
                                color: #3E3636;
                                line-height: 200%;
                                font-size: 14px;
                                margin-left: 2vw;
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
        }

        &_foregroundimages {
            position: absolute;
            z-index: 1;
            width: 50vw;
            height: 100%;
            &_first {
                position: absolute;
                height: 29vw;
                width: 29vw;
                z-index: 5;
                left: 60vw;
                bottom: 22%;
                background-image: url('./../../assets/jason-briscoe-S31lo3AHg3M-unsplash.jpg');
                background-size: contain;
                background-repeat: no-repeat;
            }

            &_second {
                position: absolute;
                height: 29vw;
                width: 29vw;
                z-index: 4;
                left: 62vw;
                bottom: 25%;
                background-image: url('./../../assets/museums-victoria-FOPuzIKOnA0-unsplash.jpg');
                background-size: contain;
                background-repeat: no-repeat;
            }

            &_third {
                position: absolute;
                height: 29vw;
                width: 29vw;
                z-index: 3;
                left: 64vw;
                bottom: 29%;
                background-image: url('./../../assets/chuttersnap-_EFvjSgbw1c-unsplash.jpg');
                background-size: contain;
                background-repeat: no-repeat;
            }

            &_fourth {
                position: absolute;
                height: 29vw;
                width: 29vw;
                z-index: 2;
                left: 66vw;
                bottom: 33%;
                background-image: url('./../../assets/andre-benz-TYjU0fpFLNY-unsplash.jpg');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
}

.closed_icon {
    background-image: url('./../../assets/openicon.png');
    width: 0.9vw;
    height: 0.9vw;
    background-size: contain;

    @media screen and (max-width: 500px) {
        width: 3vw;
        height: 3vw;
    }
}

.closed {
    display: none;
}

.openicon_button {
    height: 100%;
    background-color: transparent;
    border: none;
}

.top {
    z-index: 6;
    left: 60vw;
    bottom: 22%;
}

.opentitle {
    color: #5D91FA;
}

@media screen and (max-width: 500px) {
    .ir_wrapper {
        flex-direction: column;
        align-items: center;

        &_heading {
            display: contents;
            text-align: center;
            font-size: 3.5vw;
            font-family:  'Roboto Mono', monospace;
            color: #3E3636;

            &_text {
                margin-top: 14vw;
                margin-bottom : 14vw;
            }
        }

        &_backgroundimage {
            height: 87vw;
            width: 100vw;
            background-image: url('./../../assets/industrialRevolution_backgroundImage.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        &_contents {
            flex-direction: column;
            align-items: center;
            height: auto;
            width: 90vw;
            margin-top: 23.6vw;

            &_text {

                &_heading {
                    display: none;
                }

                &_intropara {
                    width: 100%;
                    margin: 0;
                    font-size: 10px;
                }

                &_stages {
                    margin: 0;
                    width: 100%;

                    &_one, &_two, &_three, &_four {
                        &_title {
                            font-size: 10px;
                            &_openicon {
                                width: 3vw;
                                height: 3vw;
                            }

                            &_text {
                                font-size: 3vw;
                                &_paragraph {
                                    font-size: 8px;
                                }
                            }
                        }
                    }
                }
            }

            &_backgroundimage {
                display: none;
            }

            &_foregroundimages {
                &_first {
                    height: 35vw;
                    width: 35vw;
                    z-index: 5;
                    left: 10vw;
                    top: 100vw;
                }

                &_second {
                    height: 35vw;
                    width: 35vw;
                    z-index: 4;
                    left: 30vw;
                    top: 95vw;
                }

                &_third {
                    height: 35vw;
                    width: 35vw;
                    z-index: 3;
                    left: -10vw;
                    top: 95vw;
                }

                &_fourth {
                    height: 35vw;
                    width: 35vw;
                    z-index: 2;
                    left: 10vw;
                    top: 92vw;
                }
            }
        }
    } 

    .top {
        z-index: 6;
    }

}