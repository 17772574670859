.footer {
    background-color: #221F1F;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    padding-top: 3vw;
    font-family: 'work sans', sans-serif;

    &_buttons {
        display: flex;
        justify-content: space-between;
        width: 80vw;
        margin-left: 10vw;
        padding-bottom: 3vw;
        border-bottom: 1px solid #FFF;

        &_company {
            width: 60vw;
            display: flex;
            justify-content: space-between;

            &_logo {
                height: 54px;
                width: 140px;
                background-image: url('./../../assets/navbar_icon.png');
                background-size: contain;
                background-repeat: no-repeat;
            }

            &_ijs, &_services, &_contactus, &_privacy, &_data {
                color: #8F8F8F;
                border: none;
                background-color: rgba($color: #000000, $alpha: 0.0);
                letter-spacing: 0.1em;
                font-size: 0.8vw;
                font-weight: 700;
            }

            &_ijs {
                color: #CDCDCD;
                font-size: 1.2vw;
                letter-spacing: 0.16em;
                font-weight: 400;
            }

            &_services {
                margin-left: 2vw;
            }
        }

        &_socialmedia {
            width: 10vw;
            display: flex;
            justify-content: space-between;

            &_in, &_fb, &_instagram {
                height: 2vw;
                width: 2vw;
                background-size: contain;
                background-repeat: no-repeat;
            }

            &_in {
                background-image:url('./../../assets/invector.png') ;
            }

            &_fb {
                background-image:url('./../../assets/fbvector.png') ;
            }

            &_instagram {
                background-image:url('./../../assets/instaVector.png') ;
            }
        }
    }

    &_copyrights {
        color: #A4A4A4;
        margin-top: 1vw;
        padding-bottom: 1vw;
        font-size: calc(1vw);
    }
}

@media screen and (max-width:500px) {
    .footer {
        position: relative;
        z-index: 0;
        margin-top: 5vw;
        

        &_buttons {
            flex-direction: column;
            align-items: center;
            margin-top: 1vw;
            margin-bottom: 2vw;

            &_company {
                flex-direction: column;
                align-items: center;
                margin-bottom: 8vw;

                &_ijs, &_services, &_contactus, &_privacy, &_data {
                    margin-bottom: 2vw;
                    font-size: 12px;
                    letter-spacing: 0.1em;
                    font-weight: 700;
                }

                &_ijs {
                    margin-bottom: 8vw;
                    font-size: 5vw;
                    letter-spacing: 0.16em;
                    font-weight: 400;
                }
            }

            &_socialmedia {
                width: 20vw;

                &_in, &_fb, &_instagram {
                    height: 4vw;
                    width: 4vw;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        
        }

        &_copyrights {
            font-size: 2vw;
            padding-bottom: 2vw;
            margin-left: 23vw;
        }
    }
}
