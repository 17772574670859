.herosection_wrapper_images {
    display:grid;
    grid-gap: 0;
    height: 530px;

    &_1 {
        width: 100%;
        height: 100%;
        background-image: url('./../../assets/denys-nevozhai-7nrsVjvALnA-unsplash.jpg');
        background-size: cover;
    }

    &_2 {
        width: 33vw;
        grid-column: 2;
        background-image: url('./../../assets/heroSection_img2.png');
        background-repeat: no-repeat;
        background-size: cover;
    }

    &_3 {
        width: 25vw;
        grid-column: 3;
        background-image: url('./../../assets/heroSection_img3.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.herosection_wrapper_title {
    font-family: 'Roboto Mono', monospace;
    text-align: center;
    margin-top: 7vw;
    font-size: 32px;

    &_heading {
        margin-bottom: 12px;
        color: #3E3636;
    }

    &_moto {
        margin-top: 0px;
        color: #8F8F8F;
    }
}

@media  screen and (max-width: 500px) {

    .herosection_wrapper_images {
        display: block;
        height: 260px;

        &_1 {
            width: 100%;
            height:404px ;
            background-size: contain;
        }
        &_2, &_3{
            display: none;
        }
    }

    .herosection_wrapper_title {
        margin-top: 20vw;
        margin-bottom: 10vw;
        &_heading {
            margin-bottom: 12px;
            color: #3E3636;
            font-size: 25px;
        }
    
        &_moto {
            margin-top: 0px;
            color: #8F8F8F;
            font-size: 10px;
        }
    }
}
