.ourservices_wrapper_card {
    width: 20vw;
    height: 30vw;
    background-color: #fff;
    position: relative;
    margin-top: 3vw;
    display: flex;
    flex-direction: column;

    &_blueboxtopvertical {
        position: absolute;
        height: 25%;
        width: 1%;
        left: -5px;
        top: -5px;
        background-color: #2B71FF;
        ;
    }
    &_blueboxtophorizontal {
        position: absolute;
        height: 1%;
        width: 35%;
        left: -5px;
        top: -5px;
        background-color: #2B71FF;
        ;
    }
    &_blueboxbottomvertical {
        position: absolute;
        height: 25%;
        width: 1%;
        right: -5px;
        bottom: -5px;
        background-color: #2B71FF;
        ;
    }
    &_blueboxbottomhorizontal {
        position: absolute;
        height: 1%;
        width: 35%;
        right: -5px;
        bottom: -5px;
        background-color: #2B71FF;
        ;
    }

    &_backgroundimage {
        height: 45%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./../../assets/ourservices_cardimage.png');
    }

    &_title {
        font-family: 'Work sans', sans-serif;
        font-size: 0.75vw;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        color: #3E3636;
        margin: 1vw 2vw 0vw 2vw;
    }

    &_underline {
        margin-left: 2vw;
        height: 3px;
        width: 2vw;
        margin-top: 0.5vw;
    }

    &_paragraph {
        font-family: 'Work sans', sans-serif;
        font-size: 0.7vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: -0.03em;
        color: #3E3636;
        text-align: justify;
        margin: 1vw 2vw 1vw 2vw;
    }

    &_quote {
        font-family: 'Work sans', sans-serif;
        color: #2B71FF;
        font-size: 0.7vw;
        margin: 1vw 2vw 1vw 2vw;
    }
}

.ourservices_wrapper_card_blueboxtopvertical.inactive {
    display: none;
}
.ourservices_wrapper_card_blueboxtophorizontal.inactive {
    display: none;
}
.ourservices_wrapper_card_blueboxbottomvertical.inactive {
    display: none;
}
.ourservices_wrapper_card_blueboxbottomhorizonal.inactive {
    display: none;
}

@media screen and (max-width: 500px) {
    .ourservices_wrapper_card{
        width: 90vw;
        height: 122vw;

        &_title {
            font-size: 4vw;
            margin: auto 8vw auto 8vw;
        }

        &_underline {
            margin-left: 8vw;
            width: 8vw;
        }
        
        &_paragraph {
            font-size: 3vw;
            letter-spacing: -0.03em;
            margin: 2vh 8vw 2vw 8vw;
        }

        &_quote {
            font-family: 'Work sans', sans-serif;
            color: #2B71FF;
            font-size: 3.5vw;
            margin: 2vh 8vw 2vw 8vw;
        }
    }
}
