.ourservices_wrapper {
    width: 100vw;
    height: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: #E8F0FF;
    &_heading {
        width: auto;
        font-family: 'Roboto Mono', monospace;
        font-weight: 700;
        margin-top: 4vw;

        &_text {
            margin: 0;
            font-size: 32px;
        }
    }

    &_intropara {
        width: 40vw;
        text-align: center;
        font-family: 'Work Sans', sans-serif;

        &_text {
            font-size: 1vw;
            letter-spacing: 0.035em;
            line-height: 1vw;
            font-weight: 300;
        }
    }

    &_cards {
        width: 65vw;
        display: flex;
        justify-content: space-between;
    }

    &_bubbles {
        display: flex;
        justify-content: space-between;
        width: 5vw;
        margin: 1vw;
        &_shape_desktop {
            width: 0.7vw;
            height: 0.7vw;
            border-radius: 0.7vw;
            background-color: #D9D9D9;
        }
        &_shape_mobile {
            display: none;
        }
    }
}

.ourservices_wrapper_card.inactive {
    display: none;
}

.ourservices_wrapper_bubbles_shape_desktop.active {
    background-color: #5D91FA;
}

@media screen and (max-width: 500px) {
    .ourservices_wrapper {
        height: 200vw;

        &_heading {
            height: 9vw;
            margin-bottom: 4vw;
            margin-top: 6vw;
            font-size: 3.5vw;

            &_text {
                font-size: 6vw;
            }
        }

        &_intropara {
            width: 90vw;
            margin-bottom: 8vw;
            letter-spacing: 0.035em;

            &_text {
                font-size: 2.5vw;
                line-height: 2.5vw;
            }
        }

        &_cards {
            width: 70vw;
            height: 122vw;
        }

        &_bubbles {
            width: 20vw;
            margin-top: 8vw;

            &_shape_mobile {
                display: block;
                height: 8px;
                width: 8px;
                border-radius: 4px;
                background-color: #D9D9D9;
            }
            &_shape_desktop {
                display: none;
            }
        }
    }

    .ourservices_wrapper_bubbles_shape_mobile.active {
        background-color: #5D91FA;
    }
}
