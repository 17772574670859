.timeline{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &_wrapper {
        width: 100vw;
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: contain;
    
        &_upperrow, &_bottomrow {
            display: flex;
            justify-content: space-around;
            padding-left: 100px;
            padding-top: 94px;
            padding-right: 100px;
            font-family: 'Work Sans', sans-serif;
    
            &_firstcard {
                height: 233px;
                width: 310px;
                background-size: cover;
            }
    
            &_secondcard {
                text-align:left ;
                margin-left: 5vw;
                height: 233px;
                width: 310px;

                &_years {
                    font-family: 'Roboto Mono', monospace;
                }
    
                &_text {
                    color: #646262;
                    font-size: 1vw;
                    line-height: 166%;
                }
            }
    
            &_thirdcard {
                height: 233px;
                width: 310px;
                background-size: cover;
            }
    
            &_fourthcard {
                text-align:left ;
                margin-left: 5vw;
                height: 233px;
                width: 310px;

                &_years {
                    font-family: 'Roboto Mono', monospace;
                }
    
                &_text {
                    color: #646262;
                    font-size: 1vw;
                    line-height: 166%;
                }
            }
        }
    
        &_vectormiddle {
            margin-top: 50px;
            width: 87vw;
            margin-left: 6vw;
            background-image: url('./../../assets/timelineImages_blueLongStrip.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border-left: 100px;
            border-right: 100px;
    
            &_blueellipses {
                display: flex;
                justify-content: space-between;
                padding-right: 13vw;
    
                &_1, &_2, &_3, &_4 {
                    height: 60px;
                    width: 60px;
                    background-image: url('./../../assets/timelineImages_blueellipse.png');
                    background-repeat: no-repeat;
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    &_text {
                        font-family: 'Roboto Mono', monospace;
                        color: #FFF;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    &_mobile_maintext_heading {
        display: none;
    }

    &_maintext {
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 50px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &_heading {
            text-align: left;
            font-family: 'Roboto Mono', monospace;
            color: #3E3636;
        }

        &_subtext {
            font-family: 'Work Sans', sans-serif;
            color: #3E3636;
            line-height: 23px;
            font-weight: 400;

            &_top {
                text-align: left;
            }

            &_bottom {
                text-align: left;
                margin-top: 4.5vw;
                margin-bottom: 4vw;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .timeline {
            &_wrapper {
                width: 90vw;
                display: grid;
                grid-template-columns: 40vw 10vw 40vw;
                grid-template-rows: 1;
                box-sizing: border-box;
    
                &_upperrow {
                    grid-column: 3;
                    grid-row: 1;
                }
    
                &_bottomrow {
                    grid-column: 1;
                    grid-row: 1;
                }
        
                &_upperrow, &_bottomrow {
                    display: flex;
                    flex-direction: column;
                    padding-left: 0px;
                    padding-right: 0px;
                    padding-top: 0px;
                     
                    &_firstcard, &_secondcard, &_thirdcard, &_fourthcard {   
                        height: auto;
                        width: 36vw;
                        flex-wrap: wrap;
                        margin-bottom: 5px;
                    }
    
                    &_firstcard, &_thirdcard {
                        height: 33vw;
                        width: 100%;
                    }
    
                    &_secondcard, &_fourthcard {
                        font-size: 7px;
                        margin: 0;

                        &_years {
                            font-size: 2.5vw;
                        }

                        &_text {
                            font-size: 2.5vw;
                        }
                    }
                }
        
                &_vectormiddle {
                    grid-column: 2;
                    grid-row: 1;
                    background-image: url('./../../assets/timelineImages_blueLongStrip_vertical.png');
                    height: 100%;
                    width: 100%;
                    margin: 0;
    
                    &_blueellipses {
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        height: 80%;
                        padding-right: 0;
        
                        &_1, &_2, &_3, &_4 {
                            height: 16px;
                            width: 16px;
                            background-size: contain;
            
                            &_text {
                                font-family: 'Roboto Mono', monospace;
                                color: #FFF;
                                font-size: 8px;
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
    
                img {
                    height: 15px;
                    width: 20px;
                }
            }
        

        &_maintext {
            display: block;
            padding: 3vw;
            &_heading {
                display: none;
            }

            &_subtext {
                &_top {
                    font-size: 12px;
                    text-align: center;
                }

                &_bottom {
                    font-size: 8px;
                    text-align: center;
                }
            }
        }

        &_mobile_maintext_heading {
            display: contents;
            text-align: center;
            font-family: 'Roboto Mono', monospace;
            color: #3E3636;
            font-size: 3vw;
        }
    }
}