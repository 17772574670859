.getintouch_wrapper {
    width: 100vw;
    height: 60vw;
    box-sizing: border-box;
    background-color: #F9F9F9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 3vw;


    &_form {
        width: 56vw;
        height: 36vw;
        background-size: contain;
        background-repeat: no-repeat;
        padding-left: 5vw;
        padding-top: 2vw;
        box-sizing: border-box;
        font-family: 'roboto mono', monospace;
        font-size: 2vw;

        &_paragraph {
            width: 40vw;
            text-align: justify;
            font-family: 'work sans', sans-serif;
            font-size: calc(12px + 0.2vw);
            letter-spacing: 0.1em;
            line-height: 23px;
        }

        &_form {
            font-family: 'work sans', sans-serif;
            width: 37vw;

            &_email {
                border-bottom: 1px solid #DEDEDE;
                margin-bottom: 3vw;

                &_emailinput {
                    height: 2vw;
                    background-color: rgba($color: #000000, $alpha: 0.0);
                    border: none;
                    color: #FFFFFF;
                    outline: none;
                }
            }
            &_message {
                border-bottom: 1px solid #DEDEDE;
                margin-bottom: 3vw;

                &_messageinput {
                    height: 2vw;
                    background-color: rgba($color: #000000, $alpha: 0.0);
                    border: none;
                    color: #FFFFFF;
                    outline: none;
                }
            }
        }

        &_contacts {
            font-family: 'work sans', sans-serif;
            font-size: calc(12px + 0.2vw);
            letter-spacing: 0.16em;
            display: flex;
            justify-content: space-between;
            width: 37vw;

            &_email {
                display: flex;

                &_vector {
                    object-fit: contain;
                    margin-right: 0.7vw;
                }
            }

            &_phone {
                display: flex;

                &_vector {
                    object-fit: contain;
                    margin-right: 0.7vw;
                }
            }
        }
    }

    &_mainimage {
        background-image: url('./../../assets/alexandr-bormotin-Hd8b_WtKIck-unsplash.jpg');
        width: 37vw;
        height: 43vw;
        background-size: 100% 100%;
    }
}

.hidden {
    display: none;
}

@media screen and (max-width:500px) {
    .getintouch_wrapper {
         width: 100vw;
         height: 210vw;
         flex-direction: column;
         justify-content: flex-start;
         z-index: 1;
         padding: 0;
         box-sizing: border-box;

         &_mainimage {
            margin-left: 9vw;
            margin-right: 9vw;
            width: 80vw;
            height: 95vw;
            position: absolute;
            z-index: 2;
         }

         &_form {
            width: 100vw;
            height: 200vw;
            position: absolute;
            top: 15vw;
            z-index: 0;
            background-size: 100% 100%;
            padding-left: 0;
            padding-top: 80vw;
            display: flex;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;

            &_title {
                margin-bottom: 0;
                font-size: 24px;
            }

            &_paragraph {
                width: 75vw;
                line-height: 14px;
                letter-spacing: 0.1em;
                font-weight: 500;
            }

            &_form {
                width: 76vw;

                &_email, &_message {
                    height: 6vw;
                    margin-top: 8vw;
                }

                &_email {
                    &_emailinput {
                        height: 6vw;
                        border: none;
                    }
                }

                &_message {
                    &_messageinput {
                        height: 6vw;
                        border: none;
                    }
                }
            }

            &_contacts {
                margin-top: 4vw;
                width: 76vw;
                flex-direction: column;
                letter-spacing: 0.16em;
                font-weight: 400;
                font-size: 3vw;

                &_email{
                    display: flex;
                    align-items: center;
                    &_vector {
                    height: 2vw;
                    width: 5vw;
                    }
                }

                &_phone{
                    display: flex;
                    align-items: center;
                    &_vector {
                    height: 2vw;
                    width: 5vw;
                    }
                }
            }
         }
    }
}

label {
    font-size: calc(12px + 00.2vw);
}

:root {
    --motion-ease: cubic-bezier(0.68, -0.6, 0.32, 1.6);
    --motion-duration: 0.3s;
  }
  
  button {
    appearance: none;
    background: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    font: inherit;
    font-weight: 500;
    line-height: 1;
    padding: 1em 1.5em;
    position: relative;
    transition: filter var(--motion-duration);
  }
  
  button:hover {
    filter: brightness(1.1);
  }
  
  button:active {
    filter: brightness(0.9);
  }
  
  button > span {
    display: block;
    position: relative;
    transition: transform var(--motion-duration) var(--motion-ease);
    z-index: 1;
  }
  
  button:hover > span {
    transform: scale(1.05);
  }
  
  button:active > span {
    transform: scale(0.95);
  }
  
  button > svg {
    fill: #0c6ede;
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
  }
  
  button > svg > path {
    transition: var(--motion-duration) var(--motion-ease);
  }
  
  button:hover > svg > path {
    d: path(
      "M0,0 C0,-5 100,-5 100,0 C105,0 105,100 100,100 C100,105 0,105 0,100 C-5,100 -5,0 0,0"
    );
  }
  
  button:active > svg > path {
    d: path(
      "M0,0 C30,10 70,10 100,0 C95,30 95,70 100,100 C70,90 30,90 0,100 C5,70 5,30 0,0"
    );
  }
  