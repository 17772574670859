.focusareas_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
        font-family: 'Roboto mono', monospace;
        margin-top: 5vw;
        margin-bottom: 5vw;
        font-size: 32px;
    }
}

@media screen and (max-width:500px) {
    .focusareas_wrapper {
        &_title {      
            margin-top: 10vw;
            margin-bottom: 10vw;
            font-size: 30px;
        }
    }
}