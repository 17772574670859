.focusareacard_wrapper {
    width: 60vw;
    display: flex;
    justify-content: space-between;

    &_image {
        height: 22vw;
        width: 22vw;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &_content {
        width: 35vw;

        &_title {
            display: flex;
            justify-content: space-between;
            font-family: 'Work Sans', sans-serif;
            font-weight: 100;
            font-size: 20px;
            color: #3E3636;


            &_underline {
                object-fit: contain;
            }
        }

        &_paragraph {
            text-align: justify;
            font-family: 'Work Sans', sans-serif;
            color: #656565;
            line-height: 200%;
            font-size: 1vw;
            sfont-weight: 400;
        }
    }
}

.focusareacard_wrapper.forward {
    flex-direction: row;
}

.focusareacard_wrapper.reverse {
    flex-direction: row-reverse;
}

.focusareacard_wrapper_content_title.reverse {
    flex-direction: row-reverse;
}

@media screen and (max-width:500px) {

    .focusareacard_wrapper.forward,.focusareacard_wrapper.reverse  {
        width: 100vw;
        flex-direction: column;
    }

    .focusareacard_wrapper {
        align-items: center;

        &_image {
            width: 100vw;
            height: 55vw;
            background-size: 100% 100%;
        }

        &_content {
            width: 90vw;

            &_title {
                font-size: 15px;
            }

            &_paragraph {
                font-size: 10px;
                line-height: 200%;
            }
        }
    }
}