.navigationbar_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &_icon {
        margin-top: 2vw;
        margin-left: 3vw;
        margin-bottom: 3vw;
    }

    &_buttons {
        display: block;

        &_home, &_timeline, &_services, &_contactus {
            margin: 3vw 1vw 4vw 5vw;
            border: none;
            background-color: rgba($color: #fff, $alpha: 0.1);
            font-family: 'Roboto Mono', monospace;
            color: rgba($color: #000000, $alpha: 0.4);
            font-style: normal;
            font-weight: 700;
            font-size: 1vw;
            line-height: 21px;
            letter-spacing: 0.1em;
        }
    }

    &_buttonsmobile {
        display: none;
    }

    &_menuicon {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .navigationbar_wrapper {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, auto);
        column-gap: 0px;
        &_icon {
            margin-left: 0px;
            margin-bottom: 27px;
        }

        &_buttons {
            display: none;

            &_home, &_timeline, &_services, &_contactus {
                margin: 0px;
                margin-bottom: 31px;
                font-size: 3vw;
            }
        }

        &_buttonsmobile {
            grid-row: 2;
            grid-column:1/span 2 ;
            display: flex;
            flex-direction: column;
            justify-items: start;
        }

        &_menuicon {
            display: block;
            margin-right: 8vw;
            margin-top: 5vh;
        }
    }
}
