.wrapper {

    &_mobile {
        display: none;
    }
}

.missionstatement_wrapper {
    height: 56vw;
    background-color: #E8F0FF;
    position: relative;

    &_contentcontainer {
        position: absolute;
        top: 15%;
        z-index: 1;
        height: 70%;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        &_image {
            height: 100%;
            width: 38vw;
            background-image: url('./../../assets/chor-tsang-e_5NhSomvS4-unsplash.jpg');
            background-size: contain;
            background-repeat: no-repeat;
            align-self: center;
        }

        &_text {
            width: 33vw;
            text-align: left;
            align-self: flex-start;

            &_heading {
                font-family: 'Roboto Mono', monospace;
                color:#3E3636;
                font-size: 32px;
                text-align: left;
            }

            &_paragraph {
                font-family: 'Work Sans', sans-serif;
                color:#3E3636;
                font-size: 1.2vw;
                text-align: justify;
                font-weight: 300;
                line-height: 170%;
            }
        }
    }
}

@media screen and (max-width: 500px) {

    .wrapper_mobile {
        display: contents;
        font-family: 'Roboto Mono', monospace;
        color: #3E3636;

        &_heading {
            font-size: 6.5vw;
            text-align: center;
        }
    }
    .missionstatement_wrapper {
        width: 100vw;
        height: 270vw;
        background-color: #E8F0FF;
        position: relative;

        &_backgroundimage {
            position: absolute;
            width: 100vw;
            height: 95vw;
            top: 2%;
            background-image: url('./../../assets/missionStatement_backgroundImage.png');
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 0;
        }

        &_contentcontainer {
            position: absolute;
            top: 56vw;
            z-index: 1;
            width: 100vw;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
    
            &_image {
                height: 100vw;
                width: 90vw;
                background-image: url('./../../assets/missionStatement_contentImage.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
    
            &_text {
                width: 90vw;
                text-align: justify;
                align-self: center;
                display: flex;
    
                &_heading {
                    display: none;
                }
    
                &_paragraph {
                    font-family: 'Work Sans', sans-serif;
                    color:#3E3636;
                    font-size: 3.7vw;
                    text-align: justify;
                    line-height: 170%;
                    margin: 0;
                    
                }

                &_image {
                    display: none;
                }
            }
        }
    }
}
